:root { 
--gray_50_01:#f4f8ff; 
--gray_50_02:#f8f9fa; 
--gray_50_03:#fffcf3; 
--red_700:#d03329; 
--blue_gray_50:#eaecf0; 
--blue_A700:#0061ff; 
--blue_A200:#4d90ff; 
--blue_A700_01:#0a58ff; 
--green_600:#349765; 
--gray_50:#f9fbff; 
--green_400:#56c568; 
--gray_300_99:#e4e4e499; 
--black_900_01:#090b0d; 
--black_900:#000000; 
--blue_gray_700:#424c5d; 
--blue_gray_900:#262b35; 
--gray_700_11:#55555511; 
--gray_900_02:#11142d; 
--gray_900_03:#151522; 
--blue_gray_200:#bac1ce; 
--blue_900:#003995; 
--blue_gray_100:#d6dae2; 
--blue_700:#1976d2; 
--blue_gray_400:#74839d; 
--blue_gray_300:#9ea8ba; 
--blue_800:#0051d5; 
--amber_500:#feb909; 
--blue_gray_600:#5f6c86; 
--blue_gray_400_90:#74839d90; 
--orange_A700:#ff6700; 
--gray_900:#2a2a2a; 
--gray_900_01:#0d1624; 
--amber_700:#da9e01; 
--blue_gray_300_87:#919eab87; 
--black_900_0c:#0000000c; 
--blue_50:#e0ebff; 
--gray_100:#f4fbf7; 
--gray_800_2b:#4545452b; 
--white_A700:#ffffff; 
}