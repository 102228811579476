
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/OpenSansRomanSemiBold.ttf"); font-family : "Open Sans"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/MontserratMedium.ttf"); font-family : "Montserrat"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/UrbanistRegular.ttf"); font-family : "Urbanist"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/LatoRegular.ttf"); font-family : "Lato"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/UrbanistSemiBold.ttf"); font-family : "Urbanist"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/UrbanistMedium.ttf"); font-family : "Urbanist"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/OpenSansRomanRegular.ttf"); font-family : "Open Sans"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsRegular.ttf"); font-family : "Poppins"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/LatoBold.ttf"); font-family : "Lato"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RobotoRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/SaralaBold.ttf"); font-family : "Sarala"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterSemiBold.ttf"); font-family : "Inter"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterMedium.ttf"); font-family : "Inter"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/OpenSansMedium.ttf"); font-family : "Open Sans"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/OpenSansSemiBold.ttf"); font-family : "Open Sans"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/UrbanistItalicThin.ttf"); font-family : "Urbanist"; font-weight : 100;  }